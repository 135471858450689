<script lang="ts" setup>
import { capitalize } from "vue";

// compassbles
const { t } = useLang();

// components
const PageWrapper = resolveComponent("PageWrapper");

// compiler macro
const props = defineProps({
  code: {
    type: Number,
    default: 400,
  },
  wrap: {
    type: Boolean,
    default: false,
  },
});

useHead(() => ({
  title: capitalize(t("pages.error.title", { code: props.code })),
  meta: [
    {
      name: "robots",
      content: "noindex, nofollow",
    },
  ],
}));
</script>

<template>
  <component
    :is="props.wrap ? PageWrapper : 'div'"
    :class="props.wrap ? 'flex flex-col items-center justify-center' : ''"
  >
    <h1 class="text-center mb-6 leading-3">
      <span class="font-bold text-8xl block">{{ code ?? 500 }}</span>
      <span class="block italic">{{
        t("pages.error." + code + ".message") ?? "Unknown Error"
      }}</span>
    </h1>
    <Button
      v-if="code === 401"
      :text="t('pages.auth.nav')"
      to="/auth/login"
      size="sm"
    />
    <Button v-else :text="t('pages.index.nav')" to="/" size="sm" />
  </component>
</template>
